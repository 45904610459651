import React from 'react';
import { Dialog, Icon } from '@cof/gravity-react';

import { general, errorMessages } from '../../strings';

const ErrorDialog = ({ activeLanguage, active, onCancel, depositAmount, message }) => {
  let error;
  switch (message) {
  case 'invalidApplicant':
    error = errorMessages[activeLanguage].InvalidApplicant;
    break;
  case 'cardActive':
    error = errorMessages[activeLanguage].CardActive;
    break;
  case 'ineligibleToFund':
    error = errorMessages[activeLanguage].FundErrorMessage;
    break;
  case 'badBankAccount':
    error = errorMessages[activeLanguage].InvalidBankAccount;
    break;
  case 'submitBadRequest':
    error = errorMessages[activeLanguage].SubmitApplicationBadRequest;
    break;
  case 'submitInvalidSRN':
    error = errorMessages[activeLanguage].SubmitApplicationInvalidSRN;
    break;
  case 'submitDepositExceedsMax':
    error = errorMessages[activeLanguage].DepositExceedMax + depositAmount.toString() + '.';
    break;
  case 'submitApplicationFailed':
    error = errorMessages[activeLanguage].SubmitApplicationFailed;
    break;
  case 'submitConfirmationFailed':
    error = errorMessages[activeLanguage].DepositConfirmationFailed;
    break;
  default:
    error = errorMessages[activeLanguage].GenericErrorMessage;
  }

  return (
    <Dialog
      id="error-dialog"
      active={active}
      onOk={onCancel}
      onCancel={onCancel}
      hideCancel
      title=""
      okButtonType="regressive"
      okText={general[activeLanguage].Close}
      icon={<Icon type="CautionLined"></Icon>}
      style={{ 'textAlign': 'center' }}
      className="dialog"
    >
      {error}
    </Dialog>
  );
};

export default ErrorDialog;
