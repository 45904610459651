const SecuredCardService = {

  async authorize(data) {
    const response = await fetch(`${process.env.REACT_APP_FUNDING_ELIGIBILITY_URL}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json;v=2',
        'Content-Type': 'application/json;v=2;charset=UTF-8',
      },
      body: JSON.stringify({
        'applicationId': data.srn,
        'authZip': data.zipCode,
        'lastFourSSN': data.social,
        'applicationEntryPoint': data.applicationEntryPoint || 'fromNewUI',
      }),
    });
    return response.ok ? response.json() : { 'error': response.status };
  },

  async getDepositHistory(data) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/get-funding-transactions`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json;v=2',
        'Content-Type': 'application/json;v=2;charset=UTF-8',
      },
      body: JSON.stringify({
        'applicationId': data.srn,
        'applicationEntryPoint': data.applicationEntryPoint || 'fromNewUI',
      }),
    });
    return response.ok ? response.json() : { 'error': response.status };
  },

  async validateRoutingNumber(data) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/validate-aba-number`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json;v=2',
        'Content-Type': 'application/json; v=2;charset=UTF-8',
      },
      body: JSON.stringify({
        'countryCode': 'USA',
        'abaNumber': data.routingNumber,
        'applicationId': data.srn,
        'applicationEntryPoint': data.applicationEntryPoint || 'fromNewUI',
      }),
    });
    return response.ok ? response.json() : { 'error': response.status };
  },

  async validateBankAccount(data) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/validate-bad-bank-account`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json;v=2',
        'Content-Type': 'application/json; v=2;charset=UTF-8',
      },
      body: JSON.stringify({
        'accountNumber': data.accountNumber,
        'abaNumber': data.routingNumber,
        'applicationId': data.srn,
        'applicationEntryPoint': data.applicationEntryPoint || 'fromNewUI',
      }),
    });
    return response.ok ? response.json() : { 'error': response.status };
  },

  async submitDeposit(data) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/create-funding-transaction`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json;v=2',
        'Content-Type': 'application/json; v=2;charset=UTF-8',
      },
      body: JSON.stringify({
        'applicationId': data.srn,
        'transactionAmount': (data.depositAmount * 1),
        'abaNumber': data.routingNumber,
        'accountHolderName': `${data.firstName} ${data.lastName}`,
        'accountType': data.accountType,
        'bankAccountNumber': data.accountNumber,
        'authZip': data.zipCode,
        'lastFourSSN': data.social,
        'address': [{
          'addressLine1': data.address1,
          'addressLine2': data.address2,
          'city': data.city,
          'stateCode': data.state,
          'postalCode': data.billingZip,
        }],
        'nacha': [{
          'agreementDate': new Date().toISOString(),
          'versionNumber': 4,
          'hasAgreementAcceptance': true,
        }],
        'agentId': data.eid || null,
        'transactionDate': null,
        'transactionEntryChannel': data.eid ? 'TEL' : 'WEB',
        'applicationEntryPoint': data.applicationEntryPoint || 'fromNewUI',
        'userAgent': navigator.userAgent,
        'language': 'en',
        'countryCode': 'USA',
      }),
    });
    return response.ok ? response.json() : { 'error': response.status };
  },
};

export default SecuredCardService;

