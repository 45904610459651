import React from 'react';
import { Dialog, Table, Icon } from '@cof/gravity-react';

import { general, depositHistoryStrings } from '../../strings';

import './DepositHistoryDialog.scss';

const DepositHistoryDialog = ({ activeLanguage, active, onCancel, depositHistory }) => {
  const transactions = depositHistory || [];
  const isMobile = window.matchMedia('(max-width: 800px)').matches;

  const columns = [
    {
      title: depositHistoryStrings[activeLanguage].SubmittedOn,
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: depositHistoryStrings[activeLanguage].DepositAmount,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: depositHistoryStrings[activeLanguage].SourceAccountLastFour,
      dataIndex: 'account',
      key: 'account',
    },
    {
      title: depositHistoryStrings[activeLanguage].ConfirmationNumber,
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: depositHistoryStrings[activeLanguage].DepositType,
      dataIndex: 'type',
      key: 'type',
    },
  ];

  if (isMobile) {
    columns.pop();
  }

  transactions.sort((a, b) => {
    // transactionDate is expected to be in format of YYYY-MM-DD
    const date1 = new Date(a.transactionDate);
    const date2 = new Date(b.transactionDate);

    return date2 - date1; // descending order
  });

  const formattedTransactions = transactions.map((item, index) => {
    const date = new Date(item.transactionDate);
    const dateString = date.toLocaleDateString(activeLanguage + '-us', { timeZone: 'UTC' });
    const len = dateString.length;
    const formattedDate = dateString.substring(0, len - 4) + dateString.substring(len - 2);

    return ({
      key: index.toString(),
      date: formattedDate,
      amount: `$${item.transactionAmount}`,
      id: item.transactionReferenceNumber,
      type: depositHistoryStrings[activeLanguage][item.transactionType],
      account: `...${item.lastFourBAN.slice(-4)}`,
    });
  });

  return (
    <Dialog
      id="deposit-history-dialog"
      active={active}
      onOk={onCancel}
      onCancel={onCancel}
      hideCancel
      title={depositHistoryStrings[activeLanguage].DepositHistoryTitle}
      okButtonType="regressive"
      okText={general[activeLanguage].Close}
      size="large"
      icon={<Icon type="BillLined"></Icon>}
      className="dialog"
    >
      {
        transactions.length ?
          <Table className={isMobile ? 'table-mobile' : ''} dataSource={formattedTransactions} columns={columns} /> :
          depositHistoryStrings[activeLanguage].NoDepositHistory
      }
    </Dialog>
  );
};

export default DepositHistoryDialog;
