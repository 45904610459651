import React from 'react';
import { Dialog, Icon } from '@cof/gravity-react';

import { languageDialog, general } from '../../strings';

const LanguageDialog = ({ active, onCancel, setLanguage }) => {
  return (
    <Dialog
      id="language-dialog"
      active={active}
      onOk={setLanguage}
      onCancel={onCancel}
      hideCancel
      title={languageDialog.ModalTitle}
      okButtonType="progressive"
      okText={general.es.Continue}
      size="large"
      icon={<Icon type="GlobeLined"></Icon>}
      className="dialog"
    >
      {languageDialog.ModalTerms}
    </Dialog>
  );
};

export default LanguageDialog;
