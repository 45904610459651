import React, { useState } from 'react';
import { Button, Input, HeroTitle, Fieldset, Grid, Row, Col } from '@cof/gravity-react';

import { general, agentFormStrings } from '../../strings';

import './AgentForm.scss';

const alphaNumRegex = /^[a-zA-Z0-9]*$/;
const eidRegex = /^[a-zA-Z]{3}[0-9]{3}$/;

const maskEidInput = (e, setFunction) => {
  if (alphaNumRegex.test(e.target.value) && (e.target.value.length <= 6)) {
    setFunction(e.target.value);
  }
};

const AgentForm = ({ activeLanguage, advancePage }) => {
  const [eid, setEid] = useState('');
  const [eidConfirm, setEidConfirm] = useState('');
  const [optionalData, setOptionalData] = useState({});

  const eidError = (eid.length !== 0 && eid.length !== 6) || (eid.length === 6 && !eidRegex.test(eid));
  const eidConfirmError = (eidConfirm.length !== 0 && eidConfirm !== eid) || (eidConfirm.length === 6 && !eidRegex.test(eidConfirm));

  const formHasEmpty = eid === '' || eidConfirm === '';
  const buttonDisabled = eidError || eidConfirmError || formHasEmpty;

  if (document.location.search.startsWith('?CustomerId=')
        && document.location.search.includes('SocialSecurityNumber')
        && document.location.search.includes('ZipCode')
        && document.location.search.includes('MinimumDepositRequired')) {
    const params = new URLSearchParams(document.location.search);
    const numberRegex = /^[0-9]*$/;
    const srn = params.get('CustomerId');
    const social = params.get('SocialSecurityNumber');
    const zipCode = params.get('ZipCode');

    if (numberRegex.test(srn)
            && numberRegex.test(social)
            && numberRegex.test(zipCode)
            && social.length === 4
            && zipCode.length === 5
            && (srn.length >= 14 && srn.length <= 17)
    ) setOptionalData({ srn, social, zipCode });

    window.history.replaceState(null, null, '/');
  }

  return (
    <div className="agent-form">
      <HeroTitle>{agentFormStrings[activeLanguage].AgentFormTitle}</HeroTitle>
      <Fieldset legend={agentFormStrings[activeLanguage].SubTitle}>
        <Grid>
          <Row margin="normal" className="input-row">
            <Col lg={6}>
              <Input
                label={agentFormStrings[activeLanguage].Eid}
                value={eid}
                id="agent-form-eid"
                error={eidError && agentFormStrings[activeLanguage].AgentEidInvalid}
                onChange={e => maskEidInput(e, setEid)}
              />
            </Col>
            <Col lg={6}>
              <Input
                label={agentFormStrings[activeLanguage].ConfirmAgentEid}
                value={eidConfirm}
                id="agent-form-confirm-eid"
                error={eidConfirmError && agentFormStrings[activeLanguage].ConfirmAgentEidInvalid}
                onChange={e => maskEidInput(e, setEidConfirm)}
                onEnter={_e => { !buttonDisabled && advancePage({ 'applicationEntryPoint': 'fromAgent', eid, ...optionalData }); }}
              />
            </Col>
          </Row>
        </Grid>
        <div className="button-group">
          <Button
            constantWidth={false}
            type="progressive"
            disabled={buttonDisabled}
            onClick={_e => { advancePage({ 'applicationEntryPoint': 'fromAgent', eid, ...optionalData }); }}
          >
            {general[activeLanguage].Continue}
          </Button>
        </div>
      </Fieldset>
    </div>
  );
};

export default AgentForm;
