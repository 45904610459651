import React from 'react';
import { Dialog, Icon } from '@cof/gravity-react';

import { general, creditLineHelp } from '../../strings';

const DepositCreditLineDialog = ({ activeLanguage, active, maxCreditLine, minCreditLine, onCancel }) => {
  return (
    <Dialog
      id="deposit-credit-line-dialog"
      active={active}
      onOk={onCancel}
      onCancel={onCancel}
      hideCancel
      title={creditLineHelp[activeLanguage].ModalTitle}
      okButtonType="regressive"
      okText={general[activeLanguage].Close}
      icon={<Icon type="BankLined" />}
      size="large"
      className="dialog"
    >
      <p>{creditLineHelp[activeLanguage].Paragraph1}</p>
      <p>{creditLineHelp[activeLanguage].Paragraph2a}{minCreditLine.toString()}{creditLineHelp[activeLanguage].Paragraph2b}</p>
      <p>{creditLineHelp[activeLanguage].Paragraph3}
        <strong>{creditLineHelp[activeLanguage].Paragraph3Bold}</strong>
        {creditLineHelp[activeLanguage].Paragraph3Part2}{maxCreditLine.toString()}.
      </p>
      <p>{creditLineHelp[activeLanguage].Paragraph4}</p>
    </Dialog>
  );
};

export default DepositCreditLineDialog;
