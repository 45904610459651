import React, { useEffect } from 'react';
import { Button, Container, Row, Col, Grid, HeroTitle, HeroNumber, HeroText, Divider } from '@cof/gravity-react';
import ProgressBar from '../ProgressBar';

import { general, depositAccountStrings, accountOwnerForm, depositSummaryStrings, confirmationPageStrings } from '../../strings';

import './DepositSummary.scss';

const DepositSummary = ({ activeLanguage, potomacLoaded, pageData, previousPage, advancePage, confirmationNum = null }) => {
  document.title = depositSummaryStrings[activeLanguage].DepositSummaryTitle;

  const srn = pageData.srn;
  useEffect(() => {
    if (potomacLoaded && !confirmationNum) {
      console.log('Sending pageView event for Deposit Summary');
      window.sp(
        'trackPageView', 
        'v2 - Deposit Summary', 
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [potomacLoaded, confirmationNum, srn]);
    
  const depositAmount = pageData.depositAmount;
  const date = new Date(Date.now());
  const abaNumber = pageData.routingNumber;
  const bankName = pageData.bankName;
  const accountNumber = pageData.accountNumber;
  const accountTypes = {
    'DDA': depositAccountStrings[activeLanguage].AccountTypesChecking,
    'SDA': depositAccountStrings[activeLanguage].AccountTypesSavings,
  };
  const accountType = accountTypes[pageData.accountType];

  const fullName = `${pageData.firstName} ${pageData.lastName}`;
  const fullAddress = `${pageData.address1}${pageData.address2 ? `, ${pageData.address2}` : ''}`;
  const cityStateZipString = `${pageData.city}, ${pageData.state} ${pageData.billingZip}`;

  const isMobile = window.matchMedia('(max-width: 800px)').matches;

  return (
    <div className="deposit-summary">
      {!confirmationNum && <HeroTitle>{depositSummaryStrings[activeLanguage].DepositSummaryTitle}</HeroTitle>}
      <div className="deposit-info-row">
        <HeroNumber className="deposit-amount" symbol="$" symbolPosition="start" value={depositAmount} label={general[activeLanguage].DepositAmount} size="small"/>
        {confirmationNum &&
                    <div className="grv-hero-numbers__container">
                      <div className="grv-hero-numbers grv-hero-numbers--center grv-hero-numbers--small">
                        <span className="grv-hero-numbers__symbol"></span>
                        <span className="grv-hero-numbers__amount">{confirmationNum}</span>
                      </div>
                      <p className="grv-hero-numbers__label grv-hero-numbers__label--small">{confirmationPageStrings[activeLanguage].ConfirmationNumber}</p>
                    </div>
        }
        <div className="grv-hero-numbers__container">
          <div className="grv-hero-numbers grv-hero-numbers--small">
            <span className="grv-hero-numbers__symbol"></span>
            <span className="grv-hero-numbers__amount">{date.toLocaleDateString(activeLanguage + '-us')}</span>
          </div>
          <p className="grv-hero-numbers__label grv-hero-numbers__label--small" style={{ float: 'right' }}>{depositSummaryStrings[activeLanguage].DepositDate}</p>
        </div>
      </div>

      { confirmationNum ? <Divider role="presentation" /> : <ProgressBar stepNumber="four" /> }

      <Grid>
        <Row>
          <Col sm={6} md={6} lg={6} className="grv-margin__bottom--normal">
            <Container backgroundColor="white" color="digital-gray-120" borderRadius={4} elevation={2} padding="normal">
              <HeroTitle className="center" size="medium">{depositAccountStrings[activeLanguage].BankingInformationTitle}</HeroTitle>
              <div className="deposit-info-row center">
                <HeroText>{bankName}</HeroText>
              </div>
              <div className="deposit-info-row">
                <HeroText>{depositSummaryStrings[activeLanguage].AccountType}</HeroText>
                <HeroText>{accountType}</HeroText>
              </div>
              <div className="deposit-info-row">
                <HeroText>{depositSummaryStrings[activeLanguage].RoutingNumber}</HeroText>
                <HeroText>{abaNumber}</HeroText>
              </div>
              <div className="deposit-info-row">
                <HeroText>{depositSummaryStrings[activeLanguage].AccountNumber}</HeroText>
                <HeroText>{accountNumber}</HeroText>
              </div>
            </Container>
          </Col>
          <Col sm={6} md={6} lg={6} className="grv-margin__bottom--normal">
            <Container backgroundColor="white" color="digital-gray-120" borderRadius={4} elevation={2} padding="normal">
              <HeroTitle className="center" size="medium">{accountOwnerForm[activeLanguage].PersonalInformationTitle}</HeroTitle>
              <div className="deposit-info-row center">
                <HeroText>{fullName}</HeroText>
              </div>
              <div className="deposit-info-row center">
                <HeroText>{fullAddress}</HeroText>
              </div>
              <div className="deposit-info-row center">
                <HeroText>{cityStateZipString}</HeroText>
              </div>
              {!isMobile &&
                                <br></br>
              }   
            </Container>
          </Col>
        </Row>
      </Grid>

      {!confirmationNum &&
                <div className="button-group">
                  <Button constantWidth={false} type="regressive" onClick={previousPage}>{general[activeLanguage].Back}</Button>
                  <Button constantWidth={false} onClick={advancePage}>{general[activeLanguage].Submit}</Button>
                </div>}
    </div>
  );
};

export default DepositSummary;
