import React from 'react';
import { HeroText, Grid, Row, Col, Button } from '@cof/gravity-react';

import { general } from '../../strings';

import './Footer.scss';
import logo from '../../c1logo.png';
import equalHousing from '../../EqualHousing_logo.jpg';

const Footer = ({ activeLanguage }) => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="sc-footer">
      <Grid>
        <Row className="legal-row">
          <Button type="text" compact={true} constantWidth={false} href="http://www.capitalone.com/identity-protection/privacy/?linkid=WWW_Z_Z_Z_GBLFO_F1_01_T_FO1">{general[activeLanguage].Privacy}</Button>
          <Button type="text" compact={true} constantWidth={false} href="http://www.capitalone.com/identity-protection/commitment/?linkid=WWW_Z_Z_Z_GBLFO_F1_02_T_FO4">{general[activeLanguage].Security}</Button>
          <Button type="text" compact={true} constantWidth={false} href="http://www.capitalone.com/legal/terms-conditions/?linkid=WWW_Z_Z_Z_GBLFO_F1_03_T_FO5">{general[activeLanguage].TermsAndConditions}</Button>
        </Row>
        <Row>
          <Col>
            <a href="https://www.capitalone.com"><img id="logo" src={logo} alt="Capital One Logo" /></a>
            <HeroText size="tiny" id="copyright">©{currentYear} Capital One</HeroText>
          </Col>
          <Col sm={2} md={2} lg={2} id="logos">
            <a id="fdic" href="http://www.fdic.gov/" aria-label="FDIC logo"> </a>
            <img id="eqHousing" src={equalHousing} alt={general[activeLanguage].EqualHousingLender} />
          </Col>
        </Row>
      </Grid>
    </footer>
  );
};

export default Footer;
