import React, { useState, useEffect } from 'react';
import { HeroTitle, HeroNumber, Button, Input, Fieldset, Row, Col, Grid, Select } from '@cof/gravity-react';
import ProgressBar from '../ProgressBar';

import { general, accountOwnerForm } from '../../strings';

import './AccountOwnerInfoForm.scss';

const numberRegex = /^[0-9]*$/;
const textRegex = /^[a-zA-Z0-9.'#, ]*$/;
const maxTextInputLength = 35;
const requiredBillingZipLength = 5;

const validateTextInput = (input, setAllValues, field) => {
  if (textRegex.test(input) && input.length <= maxTextInputLength) {
    // update state using a replace via nested spread
    setAllValues(obj => ({...obj, 
      [field]: {
        ...obj[field],
        value: input,
        checked: true,
        valid: input.trim().length > 0,
      },
    }));
  }
};

const validateBillingZipInput = (input, setAllValues, field) => {
  if (numberRegex.test(input) && input.length <= requiredBillingZipLength) {
    // update state using a replace via nested spread
    setAllValues(obj => ({...obj, 
      [field]: {
        ...obj[field],
        value: input,
        checked: true,
        valid: input.length === requiredBillingZipLength,
      },
    }));
  }
};

const AccountOwnerInfoForm = ({ activeLanguage, potomacLoaded, pageData, previousPage, showTermsDialog }) => {
  document.title = accountOwnerForm[activeLanguage].PersonalInformationTitle;

  const srn = pageData.srn;
  useEffect(() => {
    if (potomacLoaded) {
      console.log('Sending pageView event for Account Owner Form');
      window.sp(
        'trackPageView', 
        'v2 - Account Owner Form', 
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [potomacLoaded, srn]);

  const [allValues, setAllValues] = useState({
    firstName: {
      value: pageData.firstName || '',
      checked: false,
      valid: false,
      required: true,
    },
    lastName: {
      value: pageData.lastName || '',
      checked: false,
      valid: false,
      required: true,
    },
    address1: {
      value: pageData.address1 || '',
      checked: false,
      valid: false,
      required: true,
    },
    address2: {
      value: pageData.address2 || '',
      checked: true,                              // optional so always checked
      valid: true,                                // optional so always valid
      required: false,                             // marked as optional
    },
    city: {
      value: pageData.city || '',
      checked: false,
      valid: false,
      required: true,
    },
    state: {
      value: pageData.state || '',
      checked: false,
      valid: false,
      required: true,
    },
    billingZip: {
      value: pageData.billingZip || '',
      checked: false,
      valid: false,
      required: true,
    },
  });

  // create a dict of field input name -> input value
  const formData = {};
  for (let key in allValues) {
    formData[key] = allValues[key].value;
  }
    
  return (
    <div className="account-owner-info-form">
      <HeroTitle>{accountOwnerForm[activeLanguage].PersonalInformationTitle}</HeroTitle>
      <HeroNumber symbol="$" symbolPosition="start" value={pageData.depositAmount} label={general[activeLanguage].DepositAmount} size="small" />
      <ProgressBar stepNumber="three" />
      <Fieldset legend={accountOwnerForm[activeLanguage].PersonalInformationSubtitle}>
        <Grid>
          <Row margin="normal">
            <Col sm={2} lg={6}>
              <Input
                id="billing-info-first-name"
                label={accountOwnerForm[activeLanguage].FirstName}
                value={allValues.firstName.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'firstName')}
              />
            </Col>
            <Col sm={2} lg={6}>
              <Input
                id="billing-info-last-name"
                label={accountOwnerForm[activeLanguage].LastName}
                value={allValues.lastName.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'lastName')}
              />
            </Col>
          </Row>
          <Row margin="normal">
            <Col sm={3} lg={8}>
              <Input
                id="billing-info-address"
                label={accountOwnerForm[activeLanguage].StreetAddress}
                value={allValues.address1.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'address1')}
              />
            </Col>
            <Col sm={1} lg={4}>
              <Input
                id="billing-info-apt"
                label={accountOwnerForm[activeLanguage].Suite}
                value={allValues.address2.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'address2')}
                helper={accountOwnerForm[activeLanguage].SuiteClarification}
              />
            </Col>
          </Row>
          <Row margin="normal">
            <Col lg={5}>
              <Input
                id="billing-info-city"
                label={accountOwnerForm[activeLanguage].City}
                value={allValues.city.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'city')}
              />
            </Col>
            <Col sm={2} lg={4}>
              <Select
                id="billing-info-state"
                label={accountOwnerForm[activeLanguage].State}
                value={allValues.state.value}
                onChange={e => validateTextInput(e.target.value, setAllValues, 'state')}
                options={selectStateOptions}
              />
            </Col>
            <Col sm={2} lg={3}>
              <Input
                id="billing-info-zip"
                label={accountOwnerForm[activeLanguage].Zip}
                value={allValues.billingZip.value}
                onChange={e => validateBillingZipInput(e.target.value, setAllValues, 'billingZip')}
              />
            </Col>
          </Row>
        </Grid>
        <div className="button-group">
          <Button 
            id="back-button"
            constantWidth={false} 
            type="regressive" 
            onClick={() => previousPage(formData)}
          >
            {general[activeLanguage].Back}
          </Button>
          <Button 
            id="next-button"
            constantWidth={false} 
            type="progressive" 
            // only look at required fields where they must be populated and marked valid and checked
            disabled={Object.values(allValues).some(field => field.required && (field.value.trim() === '' || (field.checked && !field.valid)))}
            onClick={() => showTermsDialog(formData)}
          >
            {general[activeLanguage].Next}
          </Button>
        </div>
      </Fieldset>
    </div>
  );
};

const states = {
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arizona': 'AZ',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'District of Columbia': 'DC',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY',
  'Armed Forces AE': 'AE',
  'Armed Forces AA': 'AA',
  'Armed Forces AP': 'AP',
};

const selectStateOptions = Object.keys(states).map(abbr => ({ label: abbr, value: states[abbr] }));

export default AccountOwnerInfoForm;
