import React from 'react';
import { Accordion, Dialog, Icon } from '@cof/gravity-react';

import { general, faqDialog } from '../../strings';
import parse from 'html-react-parser';

import './FAQDialog.scss';

const FAQDialog = ({ activeLanguage, active, onCancel }) => {
  const faqs = faqDialog[activeLanguage].content.map((item, i) => {
    return (
      <Accordion.Item key={i} title={item.question} >
        {parse(item.answer)}
      </Accordion.Item>
    );
  });

  return (
    <Dialog
      id='faq-dialog'
      active={active}
      onOk={onCancel}
      onCancel={onCancel}
      hideCancel
      title={faqDialog[activeLanguage].ModalTitle}
      okButtonType='regressive'
      okText={general[activeLanguage].Close}
      size='large'
      icon={<Icon type='QuestionLined'></Icon>}
      className='dialog'
    >
      <Accordion>{faqs}</Accordion>
    </Dialog>
  );
};

export default FAQDialog;
