import React from 'react';
import { Container, Row, Col } from '@cof/gravity-react';

const FormContainer = ({ children }) => {
  return (
    <Row className="form-container" justify="center">
      <Col lg={9}>
        <Container backgroundColor="white">
          {children}
        </Container>
      </Col>
    </Row>
  );
};

export default FormContainer;
