
const newRelicIsSet = () => !!window?.newrelic;
const payloadIsValid = (payload) => typeof payload === 'object';
const warnOfInvalidLoggingPayload = (actionName, payload) => {
  console.warn(`Payload for ${actionName} is invalid but will still be logged. Payload must be in the form of an object.`, payload);
};

const logMessage = (actionName, payload = {}) => {
  if (!payloadIsValid(payload)) warnOfInvalidLoggingPayload(actionName, payload);

  if (!newRelicIsSet()) {
    // If the application is running locally or new relic is, for some reason, undefined, simply
    // log to the console.
    console.debug(actionName, payload);
    return;
  }

  window.newrelic.addPageAction(actionName, payload);
};

const logError = (errorName, payload = {}) => {
  if (!payloadIsValid(payload)) warnOfInvalidLoggingPayload(errorName, payload);

  const err = new Error(errorName);
  if (!newRelicIsSet()) {
    // If the application is running locally or new relic is, for some reason, undefined, simply
    // log to the console.
    console.error(err, payload);
    return;
  }

  window.newrelic.noticeError(err, payload);
};

export { logMessage, logError };
