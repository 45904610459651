import React, { useEffect } from 'react';
import { Dialog, Icon, Button } from '@cof/gravity-react';

import { general, tncDialog } from '../../strings';

import './TermsDialog.scss';

const TermsDialog = ({ activeLanguage, potomacLoaded, active, onCancel, nextPage, address, srn }) => {
  if (active) {
    document.title = tncDialog[activeLanguage].ModalTitle;
  }

  useEffect(() => {
    if (active && potomacLoaded) {
      console.log('Sending pageView event for Terms & Conditions');
      window.sp(
        'trackPageView',
        'v2 - Terms & Conditions',
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [active, potomacLoaded, srn]);

  const isMobile = window.matchMedia('(max-width: 800px)').matches;

  return (
    <Dialog
      id="terms-dialog"
      active={active}
      onOk={_e => nextPage(address)}
      onCancel={onCancel}
      title={tncDialog[activeLanguage].ModalTitle}
      okText={general[activeLanguage].Agree}
      size="large"
      hideCancel
      icon={<Icon type="DocumentLined"></Icon>}
      className="dialog"
    >
      <div className="termsText">
        <p>{tncDialog[activeLanguage].Paragraph1}</p>
        <p>{tncDialog[activeLanguage].Paragraph2}</p>
        <p>{tncDialog[activeLanguage].Paragraph3}</p>
        <p>{tncDialog[activeLanguage].Paragraph4}</p>
        <p>{tncDialog[activeLanguage].Paragraph5}</p>
      </div>
      {!isMobile &&
                <div className="printLink">
                  <Button href="" type="text" onClick={() => window.print()}>{general[activeLanguage].Print}</Button>
                </div>
      }
    </Dialog>
  );
};

export default TermsDialog;
