import React, { useState, useEffect } from 'react';
import { Button, Input, Fieldset, Icon, Select, HeroTitle, HeroNumber } from '@cof/gravity-react';
import ProgressBar from '../ProgressBar';

import SecuredCardService from '../../services/SecuredCardService';
import { general, depositAccountStrings } from '../../strings';

import './DepositAccountForm.scss';

const numberRegex = /^[0-9]*$/;
const routingRegex = /^[0-9]{0,9}$/;

const maskRoutingNumInput = async (e, setFunction, setBankFn, setChecked, setValid, srn, entryPoint) => {
  if (routingRegex.test(e.target.value)) {
    setBankFn(''); 
    setValid(false);
    setChecked(true);

    const routingNumber = e.target.value;
    setFunction(routingNumber);

    if (routingNumber.length === 9) {
      const response = await SecuredCardService.validateRoutingNumber({ 'applicationEntryPoint': entryPoint, 'routingNumber': routingNumber, srn });
      const validABAResponse = !('error' in response) && response.isAbaNumberValid && response.isAchCapable;

      if (validABAResponse) {
        setBankFn(response.bankName);
      }

      setValid(validABAResponse); 
    }         
  }
};

const maskAccountNumInput = (e, setFunction, acctNum = null, setChecked = null, setValid = null) => {
  if (numberRegex.test(e.target.value)) {
    setFunction(e.target.value);
    if (acctNum && e.target.value.length === acctNum.length) {
      setChecked(true);
      setValid(e.target.value === acctNum);
    }
  }
};

const DepositAccountForm = ({ activeLanguage, potomacLoaded, pageData, advancePage, previousPage, showRoutingAccountDialog }) => {
  document.title = depositAccountStrings[activeLanguage].BankingInformationTitle;

  const srn = pageData.srn;
  useEffect(() => {
    if (potomacLoaded) {
      console.log('Sending pageView event for Bank Account Form');
      window.sp(
        'trackPageView', 
        'v2 - Bank Account Form', 
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [potomacLoaded, srn]);
    
  const [accountType, setAccountType] = useState(pageData.accountType || '');
  const [bankName, setBankName] = useState(pageData.bankName || '');

  const [routingNumber, setRoutingNumber] = useState(pageData.routingNumber || '');
  const [routingChecked, setRoutingChecked] = useState('routingNumber' in pageData);
  const [routingValid, setRoutingValid] = useState(pageData.routingValid || false);

  const [accountNumber, setAccountNumber] = useState(pageData.accountNumber || '');
  const [accountChecked, setAccountChecked] = useState(false);
  const [accountValid, setAccountValid] = useState(false);

  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [confirmValid, setConfirmValid] = useState(false);

  const selectOptions = [
    { label: depositAccountStrings[activeLanguage].AccountTypesChecking, value: 'DDA' },
    { label: depositAccountStrings[activeLanguage].AccountTypesSavings, value: 'SDA' },
  ];

  const depositAmount = pageData.depositAmount;

  const invalidABALength = routingChecked && routingNumber.length < 9;
  const lookupError = routingChecked && !routingValid;
  const acctError = accountChecked && !accountValid;
  const confirmError = confirmChecked && !confirmValid;

  const formHasEmpty = accountType === '' || routingNumber === '' || accountNumber === '' || confirmAccountNumber === '';

  const buttonDisabled = invalidABALength || lookupError || acctError || confirmError || confirmAccountNumber !== accountNumber || formHasEmpty;
    
  return (
    <div className="sc-deposit-account-form">
      <HeroTitle>{depositAccountStrings[activeLanguage].BankingInformationTitle}</HeroTitle>
      <HeroNumber symbol="$" symbolPosition="start" value={depositAmount} label={general[activeLanguage].DepositAmount} size="small" />
      <ProgressBar stepNumber="two" />
      <Fieldset legend={depositAccountStrings[activeLanguage].BankingInformationSubtitle} helper={depositAccountStrings[activeLanguage].HelpMessage}>
        <Select
          options={selectOptions}
          label={depositAccountStrings[activeLanguage].AccountType}
          id="deposit-source-account-select"
          value={accountType}
          onChange={e => setAccountType(e.target.value)}
        />
        <span className="accountHelpLink" onClick={showRoutingAccountDialog}><Icon type="InformationLined"></Icon></span>
        <Input
          label={depositAccountStrings[activeLanguage].RoutingNumber}
          id="routing-number-input"
          placeholder=""
          error={(invalidABALength && depositAccountStrings[activeLanguage].RoutingNumberInvalid) || (lookupError && depositAccountStrings[activeLanguage].InvalidABANumber)}
          helper={bankName}
          value={routingNumber}
          onChange={(e) => maskRoutingNumInput(e, setRoutingNumber, setBankName, setRoutingChecked, setRoutingValid, pageData.srn, pageData.applicationEntryPoint)}
          onBlur={() => setRoutingChecked(true)}
        />
        <span className="accountHelpLink" onClick={showRoutingAccountDialog}><Icon type="InformationLined"></Icon></span>
        <Input
          label={depositAccountStrings[activeLanguage].AccountNumber}
          id="account-number-input"
          placeholder=""
          error={acctError && depositAccountStrings[activeLanguage].AccountNumberLengthInvalid}
          value={accountNumber}
          onChange={(e) => { maskAccountNumInput(e, setAccountNumber); if (confirmAccountNumber.length >= 3) setConfirmValid(confirmAccountNumber === e.target.value); }}
          onBlur={() => { setAccountValid(accountNumber.length >= 3 && accountNumber.length <= 17); setAccountChecked(true); }}
        />
        <span className="accountHelpLink" onClick={showRoutingAccountDialog}><Icon type="InformationLined"></Icon></span>
        <Input
          label={depositAccountStrings[activeLanguage].ConfirmAccountNumber}
          id="confirm-account-number-input"
          placeholder=""
          error={confirmError && depositAccountStrings[activeLanguage].AccountNumberInvalid}
          value={confirmAccountNumber}
          onChange={(e) => maskAccountNumInput(e, setConfirmAccountNumber, accountNumber, setConfirmChecked, setConfirmValid)}
          onBlur={() => { setConfirmValid(confirmAccountNumber === accountNumber); setConfirmChecked(true); }}
          onEnter={() => { confirmAccountNumber === accountNumber && !buttonDisabled && advancePage({ srn, routingNumber, accountNumber, bankName, accountType }); }}
        />
        <div className="button-group">
          <Button 
            id="back-button"
            constantWidth={false} 
            type="regressive" 
            onClick={() => previousPage({ routingNumber, accountNumber, bankName, accountType, routingValid })}
          >
            {general[activeLanguage].Back}
          </Button>
          <Button 
            id="next-button"
            constantWidth={false} 
            type="progressive" 
            disabled={buttonDisabled}
            onClick={() => advancePage({ 'applicationEntryPoint': pageData.applicationEntryPoint, srn, routingNumber, accountNumber, bankName, accountType, routingValid })}
          >
            {general[activeLanguage].Next}
          </Button>
        </div>
      </Fieldset>
    </div>
  );
};

export default DepositAccountForm;
