import React from 'react';
import { Dialog, Icon, HeroNumber, HeroText } from '@cof/gravity-react';

import { general, timeout } from '../../strings';

import './TimeoutDialog.scss';

const TimeoutDialog = ({ secondsLeft, activeLanguage, active, onCancel, timedOut }) => {

  return (
    <Dialog
      id="timeout-dialog"
      active={active}
      title={timeout[activeLanguage].WarningHeader}
      onOk={timedOut}
      okText={timeout[activeLanguage].NoThanks}
      okButtonType="regressive"
      onCancel={() => { onCancel(); }}
      cancelText={general[activeLanguage].Continue}
      cancelButtonType="progressive"
      icon={< Icon type="ClockLined" ></Icon >}
      className="dialog"
    >
      <HeroNumber center size="small" value={secondsLeft + ''} label={timeout[activeLanguage].WarningExpire} />
      <br></br>
      <HeroText>{timeout[activeLanguage].ContinueOrCancel}</HeroText>
    </Dialog >
  );
};

export default TimeoutDialog;
