import React from 'react';
import { HeroTitle, HeroText, Grid, Row, Col, Icon, Button } from '@cof/gravity-react';

import { timeout } from '../../strings';

import './TimedOut.scss';

const TimedOut = ({ activeLanguage }) => (
  <div className="timed-out">
    <Grid>
      <Row>
        <Col lg={7}>
          <div className="left-info">
            <Icon type="ClockLined"></Icon>
            <HeroTitle>{timeout[activeLanguage].TimeoutHeader}</HeroTitle>
            <HeroText>{timeout[activeLanguage].TimeoutExplanation}</HeroText>
            <br></br><br></br>
            <Button constantWidth={false} type="progessive" href="https://www.capitalone.com">{timeout[activeLanguage].VisitHomepage}</Button>
          </div>
        </Col>
        <Col lg={5}>
          <div className="right-info">
            <HeroTitle size="medium">{timeout[activeLanguage].WhatNow}</HeroTitle>
            <HeroText size="small">{timeout[activeLanguage].DepositSent}</HeroText>
            <br></br><br></br>
            <HeroText size="small">{timeout[activeLanguage].DepositNotSent}</HeroText>
          </div>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default TimedOut;
