import React from 'react';
import { Divider } from '@cof/gravity-react';

import './ProgressBar.scss';

import { progressIcons } from '../../images';

function ProgressBar({ stepNumber }) {
  return (
    <div className="divider">
      <Divider role="presentation" />
      <img src={progressIcons[stepNumber]} alt={`step ${stepNumber} of four`} />
    </div>
  );
}

export default ProgressBar;
