import React from 'react';
import { Grid, Row, Col, Icon, Button } from '@cof/gravity-react';

import { general } from '../../strings';

import './Header.scss';
import logo from '../../c1logo.png';

function Header({ activeLanguage, handleLanguageClick }) {
  return (
    <Grid className="sc-header">
      <Row align="center">
        <Col sm={2} md={2} lg={3}>
          <a href="https://www.capitalone.com"><img src={logo} alt="Capital One Logo" className="c1-logo" /></a>
        </Col>
        <Col sm={1} md={5} lg={8}>
          <Button type="text" onClick={handleLanguageClick}>{general[activeLanguage].Language}</Button>
        </Col>
        <Col sm={0} md={1} lg={1}><Icon type="LockLined" /></Col>
      </Row>
    </Grid>
  );
}

export default Header;
