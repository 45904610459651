import React, { useEffect } from 'react';
import { Button, HeroTitle, HeroText } from '@cof/gravity-react';
import DepositSummary from '../DepositSummary/DepositSummary';

import { formatDepositDueDate } from '../../utils/formatDueDate';
import { general, confirmationPageStrings } from '../../strings';
import { logMessage, logError } from '../../utils/logging';
import './ConfirmationPage.scss';

const ConfirmationPage = ({ activeLanguage, potomacLoaded, pageData }) => {
  document.title = 'Deposit Confirmation';

  const srn = pageData.srn;
  useEffect(() => {
    if (potomacLoaded) {
      console.log('Sending pageView event for Deposit Confirmation');
      window.sp(
        'trackPageView',
        'v2 - Deposit Confirmation',
        [{
          schema: 'iglu:com.capitalone.snowplow/application/jsonschema/1-0-4',
          data: {
            'applicationId': srn,
          },
        }],
      );
    }
  }, [potomacLoaded, srn]);

  const getRemainingDepositAmount = (depositMinimum, depositAmount, depositHistoryAmount) => {
    const remaining = depositMinimum - (depositHistoryAmount + (depositAmount * 1));
    const depositDoesFullyFund = depositMinimum <= depositAmount;

    if (isNaN(remaining)) {
      const logPayload = {
        depositMinimum,
        depositAmount,
        depositHistoryAmount,
        applicationId: srn,
        visibility: depositDoesFullyFund ? 'User did not see NaN error' : 'User did see NaN error',
      };
      logError('Remaining deposit amount is NaN', logPayload);
    } else {
      logMessage('Remaining deposit amount is not NaN');
    }

    if (isNaN(remaining)) {
      const message = depositDoesFullyFund ? 'User did not see NaN error' : 'User did see NaN error';
      const dataRaw = {
        depositMinimum,
        depositAmount,
        depositHistoryAmount,
        applicationId: srn,
      };
      logError(`Remaining amount is NaN (${message})`, { 'NaN raw data' : JSON.stringify(dataRaw) });
    } else {
      logMessage('Remaining amount is not NaN');
    }

    // Early return if the user's funded amount was greater than the minimum. This potentially fixes an issue
    // where users would see NaN if the depositHistoryTotal was a string.
    if (depositDoesFullyFund) return 0;

    return remaining;
  };

  const remaining = getRemainingDepositAmount(pageData.minimumDepositAmount, pageData.depositAmount, pageData.depositHistoryTotal);

  const cardFunded = remaining <= 0;
  const dueDate = formatDepositDueDate(pageData.depositDueDate);

  const isMobile = window.matchMedia('(max-width: 800px)').matches;

  return (
    <div className="confirmation-page">
      {!cardFunded && <HeroTitle className="remaining-box">${remaining}<br></br>{confirmationPageStrings[activeLanguage].Remaining}</HeroTitle>}
      <HeroTitle className="confirmation-text" size="medium">{cardFunded ? confirmationPageStrings[activeLanguage].Congratulations : confirmationPageStrings[activeLanguage].AlmostThere}</HeroTitle>
      <HeroText className="confirmation-text">{
        cardFunded ?
          confirmationPageStrings[activeLanguage].FullyFunded :
          `${confirmationPageStrings[activeLanguage].PartiallyFunded}$${remaining}${confirmationPageStrings[activeLanguage].PartiallyFunded2}${dueDate.toLocaleDateString(activeLanguage + '-us')}${confirmationPageStrings[activeLanguage].PartiallyFunded3}`
      }</HeroText>
      <br></br><br></br>
      <DepositSummary pageData={pageData} activeLanguage={activeLanguage} confirmationNum={pageData.transactionReferenceNumber} />
      {!isMobile &&
                <div className="printLink">
                  <Button href="" type="text" onClick={() => window.print()}>{general[activeLanguage].Print}</Button>
                </div>
      }
    </div>
  );
};

export default ConfirmationPage;
