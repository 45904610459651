import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(
  '%c   _____             _  _        _     _____\n' +
    '  /  _  \\           (_)| |      | |   /  _  \\\n' +
    '  | | |_| __ _ _ __  _ | |_ __ _| |   | | | | _ __   ___\n' +
    '  | |  _ / _` | `_ \\| || __/ _` | |   | | | || `_ \\ / _ \\\n' +
    '  | |_| | (_| | |_) | || || (_| | |   | |_| || | | |  __/\n' +
    '  \\_____/\\__,_|  __/|_| \\__\\__,_|_|   \\_____/|_| |_|\\___|\n' +
    '              | |                                         \n' +
    '              \'-\'                                         \n' +
    '  Want more than credit cards in your wallet?           \n' +
    '  We\'re always hiring great software engineers!         \n' +
    '  Check out www.capitalonecareers.com for more info.    \n' +
    '                                                       ',
  'font-family: Menlo, monospace;',
);
